.App {
  text-align: center;
  background-image: url("/public/images/orthobackgroundgradient.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("/public/images/orthobackgroundgradient.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
  text-align: center !important;
  font-size: xx-large !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.card {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row !important;
  border: none !important;
  align-items: center;
  justify-content: center;
  padding: 25px 25px;
  gap: 20px;
  overflow: hidden;
}

.socialContainer {
  width: 52px;
  height: 52px;
  border-radius: 5px;
  background-color: rgb(44, 44, 44);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition-duration: 0.3s;
}

.socialContainer.containerOne {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-containerinsta img {
  width: 100%;
  height: 100%;
}

.socialContainer.containerTwo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-containerface img {
  width: 100%;
  height: 100%;
}

.containerOne:hover {
  background-color: #d62976;
  transition-duration: 0.3s;
}

.containerTwo:hover {
  background-color: #1877f2;
  transition-duration: 0.3s;
}

.containerThree:hover {
  background-color: #d32323;
  transition-duration: 0.3s;
}

.socialContainer:active {
  transform: scale(0.9);
  transition-duration: 0.3s;
}

.socialSvg {
  width: 100%;
  height: 100%;
}

.socialSvg path {
  fill: rgb(255, 255, 255);
}

.socialContainer:hover .socialSvg {
  animation: slide-in-top 0.3s both;
}

@keyframes slide-in-top {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.slogan {
  font-style: italic;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  margin: 5px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}
@media (max-width: 597px){
  .App-header{
    flex-direction: column-reverse;
  }
  
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Greetings-Home {
  height: 115px;
  text-align: center;
}
.Contact-Main {
  background-image: url("/public/images/orthobackgroundgradient.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: 100vh;
}
.Sent-status {
  text-align: center;
  margin-top: 20px;
}

.Navbar {
  background-color: white !important;
}
.navbar-brand {
  display: flex;
  align-items: center;
}
@media (max-width: 540px) {
  .navbar-brand {
    flex-direction: column;
  }
}

.Navbar .nav-link,
.Navbar .navbar-nav .nav-link {
  color: #00a9bb !important;
}
.Navbar .nav-link:hover,
.Navbar .navbar-nav .nav-link:hover,
.Navbar .nav-link:focus,
.Navbar .navbar-nav .nav-link:focus {
  color: #035f69 !important;
}
.Navbar .nav-link.active {
  color: #013035 !important;
}
.Navbar .dropdown-menu {
  background-color: white !important;
}

.Navbar .dropdown-item {
  color: #00a9bb !important;
}

.Navbar .dropdown-item:hover {
  background-color: #035f69 !important;
  color: #013035 !important;
}

.Navbar .navbar-toggler {
  border-color: rgba(0, 0, 0, 0.1);
}

.Navbar .navbar-toggler-icon {
  filter: invert(48%) sepia(93%) saturate(1352%) hue-rotate(163deg)
    brightness(89%) contrast(89%);
}


.Navbar #dropdown-affiliates.show {
  color: #00a9bb;
}

.App {
  background-image: url("/public/images/orthobackgroundgradient.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  color: black;
}
.profiles {
  display: flex;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  font-size: small !important;
  flex-direction: column;  
  align-items: center;
}
.profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.name-button {
  background-color: #00a9bb; /* Green background */
  border: none; /* Remove border */
  color: white; /* White text */
  padding: 15px 32px; /* Padding */
  text-align: center; /* Center text */
  text-decoration: none; /* Remove underline */
  display: inline-block; /* Make it inline-block */
  font-size: xx-large; /* Font size */
  margin: 4px 2px; /* Margin */
  transition-duration: 0.4s; /* Transition duration */
  cursor: pointer; /* Cursor pointer */
  border-radius: 12px; /* Rounded corners */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* Shadow effect */
}

.name-button:hover {
  background-color: #035f69; /* Darker green background on hover */
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2); /* Increased shadow effect on hover */
}
.Biopictures {
  max-width: 100%;
  height: auto;
}

@media (max-width: 480px) {
  .Biopictures img {
    width: 100%;
  }
}

.Content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px;
  flex-direction: column-reverse;
}

.ImageContainer {
  flex: 1;
  max-width: 700px; /* Adjust as needed */
}

.ImageContainer img {
  width: 100%;
  height: 100%;
}

.map-container {
  flex: 1;
  max-width: 400px; /* Adjust as needed */
}

@media (max-width: 768px) {
  .Content {
    
    flex-direction: column;
  }
}

.Drmann-bio,
.Drjay-bio,
.Nicole-bio {
  text-align: left;
  max-width: 1000px;
  align-items: center;
  height: auto;
  padding: 10px;
  border-radius: 10px;
  margin: auto;
  box-sizing: border-box;
}
.image-images\/441519\.png {
  max-width: 300px;
  width: 100%;
  height: auto;
}

.rating {
  display: inline-flex;
  flex-direction: row-reverse;
  justify-content: center;
}


.rating input {
  display: none;
}

.rating label {
  cursor: pointer;
  font-size: 24px;
  color: grey;
}

.rating label:hover .star,
.rating label:hover ~ label .star,
.rating input:checked ~ label .star {
  color: orange;
}

form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 800px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
}

input,
textarea {
  width: 100%;
  padding: 8px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.rating {
  display: flex;
}

.rating input[type="radio"] {
  display: none;
}

.rating label {
  cursor: pointer;
  font-size: 50px;
}

button {
  background-color: #035f6900;
  color: black;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.Serviceapp-container {
  background-image: url("/public/images/orthobackgroundgradient.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.Services-Intro {
  color: black;
}

.Grid-text-all {
  display: block;
  align-items: center;
  justify-content: center;
}
.grid {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  max-width: 400px;
  margin: 20px auto;
}
@media (max-width: 650px) {
  .grid {
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(2, minmax(200px, 1fr));
    gap: 10px;
    max-width: 400px;
    margin: 20px auto;
  }
}
@media (max-width: 440px) {
  .grid {
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(1, minmax(200px, 1fr));
    gap: 10px;
    max-width: 400px;
    margin: 20px auto;
  }
}
.square {
  padding: 20px;
  text-align: center;
  cursor: pointer;
  width: 200px;
  height: 200px;
  font-size: 20px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
}

.square-text {
  color: white;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
  padding: 1px 5px;
}

.info-box {
  text-align: left;
  width: 1289px;
  height: auto;
  padding: 20px;
  color: black;
  border-radius: 10px;
  font-size: medium !important;
}

@media (max-width: 1307px) {
  .info-box {
    text-align: left;
    width: 90%;
    height: auto;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
    padding: 20px;
    background: #ffc157;
    color: black;
    border-radius: 10px;
  }
}

@media (max-width: 480px) {
  .info-box {
    text-align: left;
    width: 100%;
    height: auto;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
    padding: 20px;
    background: #ffc157;
    color: black;
    border-radius: 10px;
  }
}

.Reviewsbox {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
}

.Reviewheader {
  display: flex;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
  border-radius: 20px;
  padding: 20px;
  margin: 20px;
  border: 3px solid white;
}

@media (max-width: 352px) {
  .Reviewsbox .Reviewheader {
    width: 100%; /* Ensures the container takes up 100% of the viewport width */
    padding: 10px; /* Adjust padding as needed */
    margin: 0 auto;
    flex-direction: column;
  }
}

.Review-info {
  display: flex;
  margin-right: 40px;
  flex-direction: column;
  align-items: flex-start;
  max-width: 100%;
}

.Reviewtextbox {
  font-size: 20px;
  border-radius: 20px;
  padding: 10px;
  margin: 10px 0;
  order: 2;
}

.Gold-stars {
  color: gold;
  font-size: 30px;
}

.review-name {
  font-size: 25px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.review-date {
  font-size: 15px;
}

.review-rating {
  font-size: 20px;
}
.review-text {
  font-size: 20px;
  border-radius: 20px;
  padding: 10px;
  margin: 10px 0;
  max-width: 100%;
}

.slideshow {
  width: 100%;
  overflow: hidden;
}

.slide {
  width: 100%;
  display: flex;
  justify-content: center;
  animation: slideIn 1s forwards;
}

.slide img {
  max-width: 100%;
  height: auto;
}

.slide.transitioning {
  animation: slideOut 1s forwards;
}

@keyframes slideIn {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}

.row .provider-imgs {
  flex-shrink: 1;
  width: auto;
  max-width: 500px;
  padding-left: 0;
  margin-top: 0;
}

.provider-imgs {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  justify-content: center;
  align-items: center;
  max-width: 500px;
  margin: 0 auto;
  background-color: #ffffff;
}

.provider-imgs figure {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.provider-imgs img {
  display: block;
  width: 100%;
  max-width: 106px;
  height: auto;
}

.row {
  font-size: small;
  padding-top: 50px;
}

.Disclaimer {
  font-size: medium !important;
}
